import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import controller from './project.controller'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import { Row, Col, Switch, Select, Divider, Input, Tooltip, Button, Popconfirm, InputNumber } from "antd";
import { storeLocal } from '../../../helpers/stores'
import { ErrorByPricingPlan } from '../../../components/ErrorByPricingPlan'
import { InfoCircleOutlined } from '@ant-design/icons'
import { __product } from "../../catalog/product/__product";
import { SwitchBlock } from "../../../components/SwitchBlock";

export const SettingsProject = observer(() => {
    useEffect(() => {
        controller.get()
    }, [])

    const buttons = [
        { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
    ]

    const selectOptions = [
        {value: 1, label: i.t('settings:project.selectOptions.list')},
        {value: 2, label: i.t('settings:project.selectOptions.tile')},
        {value: 5, label: i.t('settings:project.selectOptions.horizontal')},
        {value: 6, label: i.t('settings:project.selectOptions.horizontalTight')},
        {value: 9, label: i.t('settings:project.selectOptions.tile2')},
    ]

    const displays = [
        { label: i.t('def:displays.e_1'), value: 1 },
        { label: i.t('def:displays.e_2'), value: 2 },
        { label: i.t('def:displays.e_3'), value: 3 },
        { label: i.t('def:displays.e_4'), value: 4 },
        { label: i.t('def:displays.e_5'), value: 5 },
        { label: i.t('def:displays.e_6'), value: 6 },
        { label: i.t('def:displays.e_7'), value: 7 },
        { label: i.t('def:displays.e_8'), value: 8 },
        { label: i.t('def:displays.e_9'), value: 9 }
    ]

    return(
        <>
            <form onSubmit={e => controller.save(e)}>
                <Titles
                    title={i.t('settings:project.title')}
                    buttons={buttons}
                />

                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <Divider orientation="left">{i.t('settings:project.places')}</Divider>
                    </Col>

                    <Col xs={24}>
                        <Row gutter={[60, 60]}>
                            <Col xs={24} md={12}>
                                <Row gutter={[20, 20]}>
                                    <Col xs={24} className="switch-block">
                                        <ErrorByPricingPlan
                                            plan={2}
                                            content={
                                                <div className="element">
                                                    <div></div>
                                                    <Switch
                                                        checked={controller.project.cities_enabled}
                                                        onChange={e => controller.set('cities_enabled', e)}
                                                        disabled={storeLocal.get('pricing_plan') < 2}
                                                        size="small"
                                                    />
                                                    <div>
                                                        <span>{i.t('settings:project.citiesEnabled')}</span>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </Col>
                                    {controller.project.cities_enabled ?
                                        <>
                                            <Col xs={24}>
                                                <label>{i.t('settings:project.citiesView')}</label>
                                                <Select
                                                    value={controller.project.cities_view}
                                                    style={{ width: 100 + '%' }}
                                                    onChange={e => controller.set('cities_view', e)}
                                                    options={selectOptions}
                                                />
                                            </Col>
                                        </>
                                        : ''}
                                </Row>
                            </Col>
                            <Col xs={24} md={12}>
                                <Row gutter={[20, 20]}>
                                    <Col xs={24} className="switch-block">
                                        <ErrorByPricingPlan
                                            plan={2}
                                            content={
                                                <div className="element">
                                                    <div></div>
                                                    <Switch
                                                        checked={controller.project.warehouses_enabled}
                                                        onChange={e => controller.set('warehouses_enabled', e)}
                                                        disabled={storeLocal.get('pricing_plan') < 2}
                                                        size="small"
                                                    />
                                                    <div>
                                                        <span>{i.t('settings:project.warehousesEnabled')}</span>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </Col>
                                    {controller.project.warehouses_enabled ?
                                        <>
                                            <Col xs={24}>
                                                <label>{i.t('settings:project.warehousesView')}</label>
                                                <Select
                                                    value={controller.project.warehouses_view}
                                                    style={{ width: 100 + '%' }}
                                                    onChange={e => controller.set('warehouses_view', e)}
                                                    options={selectOptions}
                                                />
                                            </Col>
                                            <Col xs={24} className="switch-block">
                                                <div className="element">
                                                    <div></div>
                                                    <Switch
                                                        checked={controller.project.warehouses_show_other_warehouses}
                                                        onChange={e => controller.set('warehouses_show_other_warehouses', e)}
                                                        size="small"
                                                    />
                                                    <div>
                                                        <span>{i.t('settings:project.warehousesShowOtherWarehouses')}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={24}>
                                                <label>{i.t('settings:project.warehousesSelectType')}</label>
                                                <Select
                                                    value={controller.project.warehouse_selection_type}
                                                    style={{ width: 100 + '%' }}
                                                    onChange={e => controller.set('warehouse_selection_type', e)}
                                                    options={[
                                                        {label: i.t('settings:project.selectOptions.list'), value: 'list'},
                                                        {label: i.t('settings:project.selectOptions.onMap'), value: 'map'}
                                                    ]}
                                                />
                                            </Col>
                                        </>
                                    : ''}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <Divider orientation="left">{i.t('settings:project.products')}</Divider>
                            </Col>
                            <Col span={24}>
                                <SwitchBlock array={[
                                    { label: i.t('settings:project.warehousesShowAllProducts'), click: (e: any) => controller.project.warehouses_show_all_products = e, value: controller.project.warehouses_show_all_products },
                                    { label: i.t('settings:project.hide_prices_un_users'), click: (e: any) => controller.project.hide_prices_un_users = e, value: controller.project.hide_prices_un_users },
                                    { label: i.t('def:Auto select variant'), click: (e: any) => controller.project.auto_select_variant = e, value: controller.project.auto_select_variant },
                                ]} />
                            </Col>
                            <Col xs={24} md={12}>
                                <Row gutter={[20, 20]}>
                                    <Button onClick={() => controller.reIndexCatalog()} danger>{i.t('def:reIndexCatalog')}</Button>
                                </Row>
                            </Col>

                        </Row>
                    </Col>
                    <Col span={24}>
                        <Divider orientation="left">{i.t('def:item_display')}</Divider>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[60, 60]}>
                            <Col xs={24} md={12}>
                                <Row gutter={[20, 20]}>
                                    <Col xs={24}>
                                        <label>{i.t('def:item_display_category_level_1')}</label>
                                        <Select
                                            value={controller.project.view_categories_level_1}
                                            style={{ width: 100 + '%' }}
                                            onChange={e => controller.set('view_categories_level_1', e)}
                                            options={displays.map(el => (el))}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <label>{i.t('def:item_display_category_level_2')}</label>
                                        <Select
                                            value={controller.project.view_categories_level_2}
                                            style={{ width: 100 + '%' }}
                                            onChange={e => controller.set('view_categories_level_2', e)}
                                            options={displays.map(el => (el))}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <label>{i.t('def:item_display_category_level_3')}</label>
                                        <Select
                                            value={controller.project.view_categories_level_3}
                                            style={{ width: 100 + '%' }}
                                            onChange={e => controller.set('view_categories_level_3', e)}
                                            options={displays.map(el => (el))}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <label>{i.t('def:view_articles_level_1')}</label>
                                        <Select
                                          value={controller.project.view_articles_level_1}
                                          style={{ width: 100 + '%' }}
                                          onChange={e => controller.set('view_articles_level_1', e)}
                                          options={selectOptions}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} md={12}>
                                <Row gutter={[20, 20]}>
                                    <Col xs={24}>
                                        <label>{i.t('def:item_display_products')}</label>
                                        <Select
                                            value={controller.project.view_products}
                                            style={{ width: 100 + '%' }}
                                            onChange={e => controller.set('view_products', e)}
                                            options={[
                                                {label: "1", value: 1},
                                                {label: "2", value: 2},
                                                {label: "3", value: 3},
                                                {label: "4", value: 4},
                                                {label: "5", value: 5},
                                                {label: "6", value: 6},
                                                {label: "7", value: 7},
                                                {label: "8", value: 8},
                                                {label: "9", value: 9},
                                                {label: "10", value: 10}
                                            ]}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <label>{i.t('def:item_display_products_home')}</label>
                                        <Select
                                            value={controller.project.view_products_in_home}
                                            style={{ width: 100 + '%' }}
                                            onChange={e => controller.set('view_products_in_home', e)}
                                            options={[
                                                {label: "1", value: 1},
                                                {label: "2", value: 2},
                                                {label: "3", value: 3},
                                            ]}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <label>{i.t('def:product_view_types_images')}</label>
                                        <Select
                                          value={controller.project.product_view_types_images}
                                          style={{ width: 100 + '%' }}
                                          onChange={e => controller.set('product_view_types_images', e)}
                                          options={[
                                              {label: i.t('def:Usual'), value: 0},
                                              {label: i.t('def:Enlarged'), value: 1},
                                          ]}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <label>{i.t('def:Places Groups Display Type')}</label>
                                        <Select
                                            value={controller.project.place_groups_view}
                                            style={{ width: 100 + '%' }}
                                            onChange={e => controller.set('place_groups_view', e)}
                                            options={selectOptions}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Divider orientation="left">{i.t('def:Scan coupons')}</Divider>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[20, 20]}>
                            <Col xs={24} className="switch-block">
                                <ErrorByPricingPlan
                                  plan={2}
                                  content={
                                      <div className="element">
                                          <div></div>
                                          <Switch
                                            checked={controller.project.scan_coupon_enabled}
                                            onChange={e => controller.set('scan_coupon_enabled', e)}
                                            size="small"
                                          />
                                          <div>
                                              <span>{i.t('def:scan_coupon_enabled')}</span>
                                          </div>
                                      </div>
                                  }
                                />
                            </Col>
                            <Col xs={24}>
                                <label>{i.t('def:scan_coupon_type')}</label>
                                <Select
                                  value={controller.project.scan_coupon_type}
                                  style={{ width: 100 + '%' }}
                                  onChange={e => controller.set('scan_coupon_type', e)}
                                  options={[
                                      {label: "QR Code", value: 'qr'},
                                      {label: "Barcode", value: 'barcode'},
                                  ]}
                                />
                            </Col>
                            <Col xs={24}>
                                <label>{i.t('def:scan_coupon_value')}</label>
                                <Select
                                  value={controller.project.scan_coupon_value}
                                  style={{ width: 100 + '%' }}
                                  onChange={e => controller.set('scan_coupon_value', e)}
                                  options={[
                                      {label: i.t('def:Coupon code'), value: 'code'},
                                      {label: i.t('def:id_crm'), value: 'id_crm'},
                                  ]}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Divider orientation="left">{i.t('def:pushesSettings')}</Divider>
                    </Col>
                    <Col span={24}>
                        <label>{i.t('def:Firebase json')}</label>
                        <Input.TextArea
                          style={{ width: '100%' }}
                          rows={6}
                          value={controller.project.firebase_json ?? ''}
                          onChange={e => controller.set('firebase_json', e.target.value)}
                        />
                    </Col>
                    <Col span={24}>
                        <Divider orientation="left">{i.t('def:otherSettings')}</Divider>
                    </Col>
                    <Col xs={24} lg={12}>
                        <label>{i.t('def:phoneCode')}</label>
                        <Input
                            value={controller.project.default_dialing_code}
                            onChange={e => controller.set('default_dialing_code', e.target.value)}
                        />
                    </Col>
                    <Col xs={24} lg={12}>
                        <label>{i.t('def:telegram_bot_token')}</label>
                        <Input
                            value={controller.project.telegram_bot_token}
                            onChange={e => controller.set('telegram_bot_token', e.target.value)}
                            suffix={
                                <Tooltip title={i.t('def:botTelegram')}>
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                    <Col xs={24} lg={12}>
                        <label>{i.t('def:deeplink')}</label>
                        <Input
                          value={controller.project.deeplink ?? ''}
                          onChange={e => controller.set('deeplink', e.target.value)}
                        />
                    </Col>
                    <Col xs={24} lg={12}>
                        <label>{i.t('def:share_text')}</label>
                        <Input
                          value={controller.project.share_text ?? ''}
                          onChange={e => controller.set('share_text', e.target.value)}
                        />
                    </Col>
                    <Col span={24}>
                        <SwitchBlock array={[
                            { label: i.t('def:Share product'), click: (e: any) => controller.project.share_product = e, value: controller.project.share_product },
                            { label: i.t('def:Prohibit registration'), click: (e: any) => controller.project.prohibit_registration = e, value: controller.project.prohibit_registration },
                        ]} />
                    </Col>
                    <Col span={24}>
                        <label>{i.t('def:Text for blocked user')}</label>
                        <Input.TextArea
                          value={controller.project.text_for_blocked_user ?? ''}
                          onChange={e => controller.set('text_for_blocked_user', e.target.value)}
                        />
                    </Col>
                    <Divider />
                    <Col span={12}>
                        <SwitchBlock array={[
                            { label: i.t('def:enabled_legal_entities'), click: (e: any) => controller.project.enabled_legal_entities = e, value: controller.project.enabled_legal_entities },
                        ]} />
                    </Col>
                    <Col span={12}>
                        <label>{i.t('def:api_fns_token')}</label>
                        <Input
                          value={controller.project.api_fns_token ?? ''}
                          onChange={e => controller.set('api_fns_token', e.target.value)}
                        />
                    </Col>
                    <Col xs={24} lg={12}>
                        <label>{i.t('def:assembly_error')}</label>
                        <span>{i.t('def:assembly_error_description')}</span>
                        <InputNumber
                          style={{ width: '100%' }}
                          value={controller.project.assembly_error ??10}
                          onChange={e => controller.set('assembly_error', e)}
                        />
                    </Col>

                    <Col span={24}>
                        <Divider orientation="left">{i.t('def:catalogClear')}</Divider>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Popconfirm
                            title={i.t('def:catalogClearText')}
                            onConfirm={() => controller.clearAllCatalog()}
                            okText={i.t('def:yes')}
                            cancelText={i.t('def:no')}
                        >
                            <Button danger type="primary">{i.t('def:clear')}</Button>
                        </Popconfirm>
                    </Col>
                </Row>
            </form>
        </>
    )
})