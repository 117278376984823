import { observer } from 'mobx-react-lite'
import { Row, Col, Input, Checkbox, Switch, Button, Select, InputNumber } from "antd";
import controller from './settings.controller'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import { useEffect } from 'react'
import settingsController from './settings.controller'
import { Block } from '../../../components/Block'
import projectController from "../project/project.controller";
import { storeLocal } from "../../../helpers/stores";
import { ErrorByPricingPlan } from "../../../components/ErrorByPricingPlan";

const Ordering = observer(() => {

    return(
        <Row gutter={[15, 15]}>
            <Col span={16}>
                <p className="label">{i.t('settings:settings.ordering.title')}</p>
            </Col>
            <div className='switch'>
                <Switch
                    size="small"
                    checked={settingsController.project.ordering > 0}
                    onChange={(e) => settingsController.onChangeProject('ordering', e ? 1 : 0)}
                />
            </div>
            {settingsController.project.ordering <= 0 &&
                <>
                    <Col span={24}>
                        <p className="label">{i.t('settings:settings.ordering.description')}</p>
                    </Col>
                    <Col span={24}>
                        <Input.TextArea
                            required
                            rows={3}
                            maxLength={255}
                            value={settingsController.project.ordering_description}
                            onChange={(e) => settingsController.onChangeProject('ordering_description', e.target.value)}
                        />
                    </Col>
                </>
            }
        </Row>
    )
})

const Cutlery = observer(() => {

    return(
        <Row gutter={[15, 15]}>
            <Col span={24}>
                <p className="label">{i.t('settings:settings.cutlery.title')}</p>
            </Col>
            <div className='switch'>
                <Switch
                    size="small"
                    checked={settingsController.project.cutlery > 0}
                    onChange={(e) => settingsController.onChangeProject('cutlery', e ? 1 : 0)}
                />
            </div>
        </Row>
    )
})

const RequiredOptions = observer(() => {

    return(
        <Row gutter={[15, 15]}>
            <Col span={24}>
                <p className="label">{i.t('settings:label.attributes')}</p>
            </Col>
            <div className='switch'>
                <Switch
                    size="small"
                    checked={controller.project.deliveryattributes > 0}
                    onChange={(e) => settingsController.onChangeProject('deliveryattributes', e ? 1 : 0)}
                />
            </div>
        </Row>
    )
})

const PlaceholderOrder = observer(() => {

    return(
        <Row gutter={[15, 15]}>
            <Col span={24}>
                <p className="label">{i.t('settings:settings.placeholder')}</p>
            </Col>
            <Col span={24}>
                <Input.TextArea
                    rows={3}
                    maxLength={255}
                    onChange={(e) => controller.onChangeProject('ordercomment', e.target.value)}
                    value={controller.project.ordercomment}
                />
            </Col>
        </Row>
    )
})

const ReceiveCode = observer(() => {

  return(
    <Row gutter={[15, 15]}>
      <Col span={24}>
        <p className="label">{i.t('settings:settings.receive_code_description')}</p>
      </Col>
      <div className='switch'>
        <ErrorByPricingPlan
          plan={2}
          black_mark={storeLocal.get('black_mark') ?? false}
          content={
            <Switch
              size="small"
              checked={projectController.project.code_receive_order_enabled}
              onChange={(e) => projectController.project.code_receive_order_enabled = e}
              disabled={storeLocal.get('pricing_plan') < 2 || storeLocal.get('black_mark')}
            />
          }
        />
      </div>
      {projectController.project.code_receive_order_enabled
        ? <>
          <Col span={24}>
            <label>{i.t('settings:project.code_receive_order_type')}</label>
            <Select
              value={projectController.project.code_receive_order_type}
              style={{ width: 100 + '%' }}
              onChange={e => projectController.project.code_receive_order_type = e}
              options={[
                {value: 'qrcode', label: "QR code"},
                {value: 'barcode', label: "Barcode"},
              ]}
            />
          </Col>
          <Col span={24}>
            <label>{i.t('settings:project.code_receive_order_format')}</label>
            <p>
              N - {i.t('def:Random number')}<br></br>
              L - {i.t('def:Random letter')}
            </p>
            <Input
              value={projectController.project.code_receive_order_format}
              onChange={e => projectController.handleChangeReceiveCode(e.target.value)}
            />
          </Col>
        </>
        : <></>
      }

    </Row>
  )
})

export const ShopSettingsOrder = observer(() => {

    useEffect(() => {
      projectController.get()
        settingsController.getProject()
            .then(result => {

            })
            .catch(error => {

            })
    }, [])

    const buttons = [
        { title: i.t('def:actions.back'), href: '/shop/settings', type: 'dashed' },
        { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
    ]

    return(
        <>
            <form onSubmit={(e) => {
              settingsController.save(e);
              projectController.saving()
            }}>
                <Titles
                    title={i.t('settings:order.title')}
                    buttons={buttons}
                />

                <Row gutter={[20, 20]}>
                    <Col span={12}>
                        <Block
                            title={i.t('settings:settings.ordering.title')}
                            slot={<Ordering />}
                        />
                    </Col>
                    <Col span={12}>
                        <Block
                            title={i.t('settings:settings.cutlery.cutlery')}
                            slot={<Cutlery />}
                        />
                    </Col>
                    <Col span={12}>
                        <Block
                            title={i.t('settings:label.comment')}
                            slot={<PlaceholderOrder />}
                        />
                    </Col>
                    <Col span={12}>
                        <Block
                            title={i.t('settings:settings.addressData')}
                            slot={<RequiredOptions />}
                        />
                    </Col>
                  <Col span={12}>
                    <Block
                      title={i.t('settings:settings.order_receive_code')}
                      slot={<ReceiveCode />}
                    />
                  </Col>
                </Row>
            </form>
        </>
    )
})