import { makeAutoObservable } from 'mobx'
import { openApi } from '../../helpers/request'
import dayjs from 'dayjs'
import { FormEvent } from 'react'
import { notification } from "antd";
import i from "../../translations/i";

export interface UserInterface {
    id: number
    firstname: string
    lastname: string
    surname: string
    email: string
    external_id: string
    phone: string
    birthday: number
    orders: any
    is_blocked: boolean
    companies: any[]
}

class UsersController {

    users: UserInterface[] = []
    user: UserInterface = {
        id: 0,
        firstname: '',
        lastname: '',
        surname: '',
        email: '',
        phone: '',
        external_id: '',
        birthday: 0,
        orders: {},
        is_blocked: false,
        companies: []
    }

    password = {
        password: '',
        password_repeat: ''
    }

    list = {
        limit: 20,
        offset: 0,
        count: 0,
    }

    list_filter: any = {
        name: null,
        email: null,
        phone: null
    }

    bonuses_add = 1
    bonuses_delete = 1
    bonuses_add_combustion_date: any = null
    bonus_balance = 0
    bonuses = []
    bonuses_limit = 10
    bonuses_page = 1
    bonuses_total = 0
    bonus_table = 1

    modalCompany = {
        open: false,
        inn: null,
        list: []
    }

    constructor() {
        makeAutoObservable(this)
    }

    deleteBonuses(e: FormEvent) {
        e.preventDefault()

        let data = new FormData()
        data.append('count_bonus', `${this.bonuses_delete}`)
        data.append('user_id', `${this.user.id}`)

        openApi('post', `/user/bonus/delete`, data)
          .then(result => {
              this.bonuses_delete = 1
              this.bonus_table = 1
              this.getHistory()
          })
    }

    addBonuses(e: FormEvent) {
        e.preventDefault()

        let data = new FormData()
        data.append('count_bonus', `${this.bonuses_add}`)
        data.append('user_id', `${this.user.id}`)
        if(this.bonuses_add_combustion_date) data.append('combustion_date', `${this.bonuses_add_combustion_date}`)

        openApi('post', `/user/bonus/add`, data)
            .then(result => {
                this.bonuses_add = 1
                this.bonuses_add_combustion_date = null
                this.bonus_table = 1
                this.getHistory()
            })
    }

    getList() {
        let uri = `/users?limit=${this.list.limit}&offset=${this.list.offset}`

        if(this.list_filter.name) uri += `&filter[name]=${this.list_filter.name}`
        if(this.list_filter.phone) uri += `&filter[phone]=${this.list_filter.phone}`
        if(this.list_filter.email) uri += `&filter[email]=${this.list_filter.email}`


        openApi('get', uri)
            .then(result => {
                console.log(result)
                result = result.data
                this.list.count = result.count
                this.users = result.list
            })
    }

    getOne(id: any) {
        if(id > 0)
            openApi('get', `/users/${id}`)
                .then(result => {
                    console.log(result)
                    this.user = result.data
                    this.getHistory()
                })
              .catch(err => console.log(err.response))
    }

    exportUsers(type: string) {
        openApi('get', `/users/export/${type}`)
            .then(result => {
                 window.open(result.data)
            })
    }

    save(e: FormEvent) {
        e.preventDefault()
        const data = new FormData()

        data.append('birthday', `${this.user.birthday}`)
        data.append('phone', `${this.user.phone}`)
        data.append('email', `${this.user.email}`)
        data.append('lastname', `${this.user.lastname}`)
        data.append('firstname', `${this.user.firstname}`)
        data.append('surname', `${this.user.surname}`)

        if(this.user.id === 0 && this.password.password === '') {
            notification.error({
                message: i.t('def:errors.error'),
                description: i.t('def:input_password')
            })
            return false
        }

        if(this.password.password !== '' && this.password.password_repeat !== '' && this.password.password === this.password.password_repeat) {
            data.append('password', this.password.password)
        } else {
            notification.error({
                message: i.t('def:errors.error'),
                description: i.t('def:errors.passwords')
            })
            return false
        }

        openApi('post', `/user/edit/${this.user.id}`, data)
            .then(result => {
                if(this.user.id > 0) this.getOne(this.user.id)
                else window.location.href = `/users/${result.data.id}`
            })
            .catch(error => console.log(error))
    }

    getHistory(type = 1) {
        openApi('get', `/user/bonuses/${this.user.id}/all?limit=${this.bonuses_limit}&page=${this.bonuses_page}&type_table=${type}`)
            .then(result => {
                console.log(result)
                result = result.data
                this.bonuses = result.list && Array.isArray(result.list) ? result.list : []
                this.bonus_balance = result.balance
                this.bonuses_total = result.total
            })
            .catch(error => console.log(error))
    }

    setTypeTable(e: any) {
        if(e && typeof e === 'number' && e >= 1 && e <= 2 && e !== this.bonus_table) {
            this.bonuses_page = 1
            this.bonuses_total = 0
            this.getHistory(e)
            this.bonus_table = e
        }
    }

    setFilter(name: string, value: string) {
        this.list_filter[name] = value !== '' ? value : null
        this.list.offset = 0
        this.getList()
    }

    blockUser(user_id = 0) {
        if(user_id > 0) {
            openApi('post', `/users/block/${user_id}`)
              .then(result => {
                  this.getOne(user_id)
              })
              .catch(error => console.log(error))
        }
    }

    searchCompany() {
        openApi('get', `/users/search_inn?user_id=${this.user.id}&inn=${this.modalCompany.inn}`)
          .then(result => {
              console.log(result)
              this.modalCompany.list = result.data
          })
          .catch(error => console.log(error.response))
    }

    openSearchCompany() {
        this.modalCompany = {
            open: true,
            inn: null,
            list: []
        }
    }

    closeSearchCompany() {
        this.modalCompany = {
            open: false,
            inn: null,
            list: []
        }
    }

    addCompanyToUser(company: any) {
        const data = new FormData()
        data.append('user_id', `${this.user.id}`)

        data.append('type', `${company.type}`);
        data.append('inn', `${company.inn}`);
        data.append('ogrn', `${company.ogrn}`);
        if(company.name_short) data.append('name_short', `${company.name_short}`);
        if(company.name) data.append('name', `${company.name}`);
        if(company.date_ogrn) data.append('date_ogrn', `${company.date_ogrn}`);
        data.append('status', `${+ company.status}`);
        if(company.address) data.append('address', `${company.address}`);
        if(company.activity) data.append('activity', `${company.activity}`);
        if(company.fio) data.append('fio', `${company.fio}`);

        openApi('post', '/users/company', data)
          .then(result => {
              console.log(result)
              this.closeSearchCompany()
              this.getOne(this.user.id)
          })
    }

    deleteCompanyFromUser(id: number) {
        openApi('delete', `/users/company/${this.user.id}/${id}`)
          .then(result => {
              console.log(result)
              this.getOne(this.user.id)
          })
    }
}

export default new UsersController()