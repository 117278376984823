import { makeObservable, observable } from 'mobx'
import { openApi } from '../../../helpers/request'
import { FormEvent } from 'react'
import userController from '../../user/controllers/user.controller'
import { notification } from 'antd'
import CryptoJS from "crypto-js";
import i from "../../../translations/i";

export interface AdminInterface {
    id: number
    email: string
    enabled: boolean,
    firstname: string
    lastname: string
    phone: string
    telegram_id: string
    right: any,
    data?: any,
}

interface ManagerRelationInterface {
    item_type: string
    item_id: number
}

interface ManagerCustomRelations {
    delivery_zones: number[]
    places: number[]
    warehouses: number[]
}

interface ManagerAccessInterface {
    item_type: string
    item_id: number
}

interface ManagerCustomAccess {
    delivery_zones_access: number[]
    places_access: number[]
    warehouses_access: number[]
}

class __admin {

    adminList: AdminInterface[] = []
    admin: AdminInterface = {
        id: 0,
        email: '',
        enabled: true,
        firstname: '',
        lastname: '',
        phone: '',
        telegram_id: '',
        right: {
            notifications: true,
            rights: 0
        },
        data: {
            telegram_id: ''
        }
    }

    managerChangePassword = {
        password: '',
        confirm_password: ''
    }

    pagination = {
        total: 0,
        limit: 1000,
        page: 1,
        offset: 0
    }

    delivery_zones: any[] = []
    places: any[] = []
    warehouses: any[] = []

    delivery_zones_access: any[] = []
    places_access: any[] = []
    warehouses_access: any[] = []

    managerRights: ManagerCustomRelations = {
        delivery_zones: [],
        places: [],
        warehouses: []
    }

    managerAccess: ManagerCustomAccess = {
        delivery_zones_access: [],
        places_access: [],
        warehouses_access: []
    }

    this_user_id = 0

    constructor() {
        makeObservable(this, {
            adminList: observable,
            admin: observable,
            pagination: observable,
            delivery_zones: observable,
            places: observable,
            warehouses: observable,
            managerRights: observable,
            managerAccess: observable,
            this_user_id: observable,
            managerChangePassword: observable
        })
    }

    getList() {
        openApi('get', `/manager/list?limit=${this.pagination.limit}&page=${this.pagination.page}`)
            .then(result => {
                console.log(result)
                this.adminList = result.data
            })
    }

    getOne(id: any) {
        if(id > 0) {} else id = 0

        openApi('get', `/manager/${id}`)
            .then(result => {
                console.log(result)

                result = result.data

                if(id > 0)
                    this.admin = result.manager

                if(result.manager.relations && Array.isArray(result.manager.relations)) {
                    console.log(result.manager.relations)
                    this.managerRights = {
                        delivery_zones: [],
                        places: [],
                        warehouses: []
                    }
                    result.manager.relations.map((el: ManagerRelationInterface) => {
                        if(el.item_type === 'place') this.managerRights.places.push(el.item_id)
                        if(el.item_type === 'warehouse') this.managerRights.warehouses.push(el.item_id)
                        if(el.item_type === 'delivery_zone') this.managerRights.delivery_zones.push(el.item_id)
                    })
                }

                if(result.manager.access && Array.isArray(result.manager.access)) {
                    this.managerAccess = {
                        delivery_zones_access: [],
                        places_access: [],
                        warehouses_access: []
                    }
                    result.manager.access.map((el: ManagerAccessInterface) => {
                        if(el.item_type === 'place') this.managerAccess.places_access.push(el.item_id)
                        if(el.item_type === 'warehouse') this.managerAccess.warehouses_access.push(el.item_id)
                        if(el.item_type === 'delivery_zone') this.managerAccess.delivery_zones_access.push(el.item_id)
                    })
                }

                if(result.delivery_zones) this.delivery_zones = result.delivery_zones
                if(result.places) this.places = result.places
                if(result.warehouses) this.warehouses = result.warehouses

                if(!result.manager.data) {
                    this.admin.data = {}
                }

                userController.decrypt('user')
                    .then(result => {
                        if(result.id) this.this_user_id = result.id
                    })
            })
    }

    saveManager(e: FormEvent) {
        e.preventDefault()

        const data = new FormData()
        data.append('email', this.admin.email)
        data.append('firstname', this.admin.firstname)
        data.append('lastname', this.admin.lastname)
        data.append('phone', this.admin.phone)
        data.append('rights', this.admin.right.rights)
        data.append('notifications', `${+this.admin.right.notifications}`)

        if(this.admin.data.telegram_id) data.append('telegram_id', this.admin.data.telegram_id)

        if(this.managerRights.places.length) {
            let i_places = 0
            this.managerRights.places.map((el: any) => {
                data.append(`places[${i_places}]`, `${el}`)
                i_places++
            })
        }

        if(this.managerRights.delivery_zones.length) {
            let i_delivery_zones = 0
            this.managerRights.delivery_zones.map((el: any) => {
                console.log(el)
                data.append(`delivery_zones[${i_delivery_zones}]`, `${el}`)
                i_delivery_zones++
            })
        }

        if(this.managerRights.warehouses.length) {
            let i_warehouses = 0
            this.managerRights.warehouses.map((el: any) => {
                data.append(`warehouses[${i_warehouses}]`, `${el}`)
                i_warehouses++
            })
        }

        if(this.managerAccess.places_access.length) {
            let i_places = 0
            this.managerAccess.places_access.map((el: any) => {
                data.append(`places_access[${i_places}]`, `${el}`)
                i_places++
            })
        }

        if(this.managerAccess.delivery_zones_access.length) {
            let i_delivery_zones = 0
            this.managerAccess.delivery_zones_access.map((el: any) => {
                console.log(el)
                data.append(`delivery_zones_access[${i_delivery_zones}]`, `${el}`)
                i_delivery_zones++
            })
        }

        if(this.managerAccess.warehouses_access.length) {
            let i_warehouses = 0
            this.managerAccess.warehouses_access.map((el: any) => {
                data.append(`warehouses_access[${i_warehouses}]`, `${el}`)
                i_warehouses++
            })
        }

        if(this.managerChangePassword.password) {
            if(this.managerChangePassword.password === this.managerChangePassword.confirm_password) {
                data.append('password', CryptoJS.SHA1(this.managerChangePassword.password).toString())
            } else {
                notification.error({
                    message: 'Ошибка',
                    description: 'Пароли не совпадают'
                })
            }
        }

        openApi('post', `/manager/edit/${this.admin.id}`, data)
            .then((result) => {
                console.log(result)

                if(this.admin.id !== result.id) window.location.href = `/settings/managers`
                else {
                    this.getOne(result.id)
                    notification.success({
                        message: 'Успешно!',
                        description: 'Данные успешно изменены'
                    })
                }
            })
            .catch((error) => {
                console.log(error.response)
                notification.error({
                    message: i.t('def:errors.error'),
                    description: error.response.data.data.message ?? i.t('def:errors.error')
                })
            })
    }

    unLink(userId: number) {
        openApi('delete', `/manager/${userId}`)
            .then((result) => {
                this.getList()
                notification.success({
                    message: 'Успешно!',
                    description: 'Элемент успешно удален'
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

}

export default new __admin()