import { observer } from 'mobx-react-lite'
import i from '../../translations/i'
import { Titles } from '../../layouts/Titles'
import __catalog from './__catalog'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { runInAction } from 'mobx'
import { ProductsList } from '../../components/list/ProductsList'
import { IProduct } from '../../components/list/types'
import { Badge, Button, Image, Space, Alert, Table, Tooltip, Switch } from 'antd'
import { DownOutlined, SettingOutlined, UnorderedListOutlined, UpOutlined, ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import __multiple from '../../helpers/multiple_actions/__multiple'
import { __product } from './product/__product'
import { storeLocal } from "../../helpers/stores";

export const CatalogList = observer(() => {

    let { categoryID = 0 }: any = useParams()

    useEffect(() => {
        return () => {
            runInAction(() => __catalog.this_category_id = Number(categoryID))
            __catalog.get()
        }
    }, [])

    return(
        <>
            <Titles
                title={i.t('categories:catalog')}
                breadcrumb={__catalog.tree}
                buttons={[
                    { title: i.t('categories:createCategory'), href: `/shop/catalog/category/create/${__catalog.this_category_id}`, type: 'dashed', disabled: storeLocal.get('role') === 4 },
                    { title: i.t('categories:createProduct'), href: `/shop/catalog/product/0/${__catalog.this_category_id}`, type: 'dashed' },
                    { title: i.t('categories:prices'), href: `/shop/mass`, type: 'dashed', disabled: storeLocal.get('role') === 4 }
                ]}
            />

            {(__catalog.productWithoutCategory > 0 && Number(storeLocal.get('role')) !== 4) &&
                <>
                    <Alert
                        message={`${i.t('categories:without')}: ${__catalog.productWithoutCategory}`}
                        type="info"
                        action={
                            <Space direction="vertical">
                                <Button size="small" type="primary" href="/shop/without">
                                    {i.t('categories:withoutList')}
                                </Button>
                            </Space>
                        }
                        closable
                    />
                    <br/>
                </>
            }

            <ListCategories />
        </>
    )
})

const ListCategories = observer(() => {

    const columns = [
        {
            title: '',
            dataIndex: 'view',
            key: 'view',
            render: (active: boolean) => (
                <Badge
                    status={active ? 'success' : 'error'}
                />
            )
        },
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('categories:picture'),
            dataIndex: 'image',
            key: 'image',
            render: (image: any) => image ? <Image width={'100px'} src={`${image.base}${image.uri}`} /> : <></>,
        },
        {
            title: i.t('categories:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i.t('def:active'),
            key: 'active',
            render: (category: any) => <Switch size="small" checked={category.view > 0} onChange={(e) => __catalog.setActiveCategory(category.id, e)}
                                               disabled={storeLocal.get('role') === 4} />
        },
        {
            title: i.t('categories:actions'),
            key: 'action',
            render: (category: any) => (
                <>
                    <Space>
                        <Tooltip title={i.t('categories:view')}>
                            <Button
                              type="dashed" shape="circle" icon={<UnorderedListOutlined />}
                              href={category.subcategories ? `/shop/catalog/category/${category.id}` : `/shop/products/${category.id}`}
                            />
                        </Tooltip>
                        <Tooltip title={i.t('categories:top')}><Button type="dashed" shape="circle" icon={<UpOutlined />} onClick={() => __catalog.setPosition(category.id)} disabled={storeLocal.get('role') === 4} /></Tooltip>
                        <Tooltip title={i.t('categories:bottom')}><Button type="dashed" shape="circle" icon={<DownOutlined />} onClick={() => __catalog.setPosition(category.id, 'down')} disabled={storeLocal.get('role') === 4} /></Tooltip>
                        <Tooltip title={i.t('categories:edit')}><Button type="dashed" shape="circle" href={`/shop/catalog/category/edit/${category.id}`} icon={<SettingOutlined />} /></Tooltip>
                    </Space>
                </>
            ),
        },
    ]

    return(
        <>
            <Table
                rowKey="id"
                rowSelection={{
                    onSelect: (record, selected, selectedRows) => {
                        __multiple.set(selectedRows)
                    },
                    onSelectAll: (selected, selectedRows) => {
                        __multiple.set(selectedRows)
                    },
                    type: 'checkbox',
                    selectedRowKeys: __multiple.selected
                }}
                columns={columns}
                dataSource={__catalog.list_categories}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: __catalog.page,
                    pageSize: __catalog.limit,
                    total: __catalog.total,
                    showSizeChanger: false,
                    onChange: (e: number) => __catalog.setPage(e)
                }}
            />
        </>
    )
})