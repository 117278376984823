import { observer } from "mobx-react-lite"
import { Button, Image, InputNumber, Space, Tag, Row, Col } from "antd";
import i from "../../../../translations/i"
import { storeSession } from "../../../../helpers/stores"
import { getPrice } from "../../../../helpers/price"
import { Controller } from "../../../../helpers/controllers/Controller";
import { customToFixed } from "../../../../helpers/project.controller";


export const OrderProducts = observer(({ items, editable, setQuantity, deleteProduct }: any) => {

  return(
    <>
        <div className="order__products">
            {items && items.map((item: any, index: number) => {
                let className = 'item'
                if(editable) {
                    className += ' item-editable'
                }
                if(item.product.is_set) className += ' item-is_set'

                return (
                  <>
                    <div className={className} key={index}>
                      <div className="item__image">
                        {item.product && item.product.image &&
                          <Image src={item.product.image.path} />
                        }
                      </div>

                      <div className="item__meta">
                        {item.product && item.product.seller &&
                          <div className="item__meta-vendor"><a href={`/settings/managers/${item.product.seller.manager_id}`} target="_blank">{i.t('def:seller')}: #{item.product.seller.manager_id} - {item.product.seller.manager.firstname ?? ''} {item.product.seller.manager.lastname ?? ''} ({item.product.seller.manager.email})</a></div>
                        }
                        {item.product && item.product.vendor_code &&
                          <div className="item__meta-vendor">{i.t('def:vendor')}: {item.product.vendor_code}</div>
                        }
                        {item.product && item.product.name &&
                          <div className="item__meta-name">{item.product.name}</div>
                        }
                        {Array.isArray(item.props) &&
                          <div className="item__meta-props">
                            {item.props.map((el: any) => (<Tag>{el.category.name}: {el.value.value}</Tag>))}
                          </div>
                        }
                      </div>

                      <div className="item__quantity">
                        {editable
                          ? <>
                            <InputNumber
                              style={{ width: '100%' }}
                              value={item.product && (item.product.weight_goods || item.product.step !== 1)
                                ? customToFixed(item.count * item.product.step, 3)
                                : item.count}
                              onChange={(e: any) => {
                                setQuantity(item.id, e, item.product.weight_goods, item.product.step)
                              }}
                            />
                          </>
                          : <>
                            {item.product && item.product.unit_id && item.product.step ? customToFixed(item.count * item.product.step, 3) : item.count}
                          </>
                        }
                        &nbsp;
                        {item.product && item.product.unit_id && storeSession.get("project_units").findIndex((el: any) => el.id === item.product.unit_id) > -1
                          ? <>{storeSession.get("project_units").find((el: any) => el.id === item.product.unit_id).name}</>
                          : <></>}
                      </div>

                      <div className="item__price">{item.price ? getPrice(customToFixed(item.price * item.count, 3)) : ''}</div>

                      {editable &&
                        <div className="item__remove">
                          <Button
                            danger
                            type="dashed"
                            onClick={() => deleteProduct(item.id)}
                          >{i.t('def:actions.remove')}</Button>
                        </div>
                      }

                    </div>

                    {item.product.is_set
                      ? <div className="order__products product_is_set">
                        <OrderProducts
                          items={item.product.sets}
                          editable={false}
                          setQuantity={null}
                          deleteProduct={null}
                        />
                        {/*{item.product.sets.map((set_product: any) => (*/}
                        {/*  <div>*/}
                        {/*    {set_product.product.name}*/}
                        {/*  </div>*/}
                        {/*))}*/}
                      </div>
                      : <></>
                    }

                  </>
                )
            })}
        </div>
    </>
  )
})